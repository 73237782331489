<script setup>
import { useRouter } from 'vue-router'

const router = useRouter()

function goTo(name) {
  router.push({
    name: name,
  })
}
</script>

<template>
  <div class="d-flex align-center justify-center h-100">
    <v-card class="bg-red-lighten-2 d-flex flex-column justify-space-evenly align-center" min-width="70%" min-height="50%">
      <v-card-title>
        <h1 class="text-h2 text-md-h1 font-weight-black">Sken SPZ</h1>
        <h3>by Trosky</h3>
      </v-card-title>

      <v-card-actions class="d-flex align-center justify-center">
        <v-btn class="mr-5" variant="outlined" @click="goTo('SPZ')">SPZ</v-btn>

        <v-btn class="ml-5" variant="outlined" @click="goTo('Inspections')">Inspections</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<style></style>