<script setup>
import { onMounted, ref } from 'vue';
import { internalApiRequest } from '@/services/apiService.js';
import { useRouter } from 'vue-router'

const router = useRouter()

const items = ref([])
const sortBy = ref([{ key: 'created_at', order: 'desc' }])
const headers = ref([
  {
    align: 'center',
    key: 'plate',
    title: 'Plate'
  },
  {
    align: 'center',
    key: 'type',
    title: 'Type'
  },
  {
    align: 'center',
    key: 'created_at',
    title: 'Created at'
  },
  {
    align: 'center',
    key: 'inspections',
    title: 'Inspections'
  },
  {
    align: 'end',
    key: 'action',
    title: '',
    sortable: false
  },
])
const spz = ref('')
const search = ref('')
const dialog = ref(false)

function fetchPlates() {
  internalApiRequest({
    method: 'GET',
    url: 'api/v1/plate',
  }).then((resp) => {
    items.value = resp
  });
} 

function addPlate() {
  internalApiRequest({
    method: 'POST',
    url: 'api/v1/plate',
    data: {
      plate: spz.value
    }
  }).then(() => {
    closeDialog();
    fetchPlates();
  });
}

function deletePlate(item) {
  internalApiRequest({
    method: 'DELETE',
    url: `api/v1/plate/${item.id}`,
  }).then(() => {
    fetchPlates();
  });
}

function disablePlate(item) {
  internalApiRequest({
    method: 'POST',
    url: `api/v1/plate/${item.id}/ban`,
  }).then(() => {
    fetchPlates();
  });
}

function allowPlate(item) {
  internalApiRequest({
    method: 'POST',
    url: `api/v1/plate/${item.id}/allow`,
  }).then(() => {
    fetchPlates();
  });
}

function closeDialog() {
  spz.value = ''
  dialog.value = false
}

function openInspectionsWithSearchedPlate(item) {
  router.push({
    name: 'Inspections',
    query: {
      plate: item.plate
    }
  })
}

function formattedDate(item) {
  const options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  
  return new Date(item.created_at).toLocaleDateString('cs-CZ', options);
}

onMounted(() => {
  fetchPlates()
});
</script>

<template>
  <v-container fluid>
    <v-card
      :title="$vuetify.display.mobile ? 'SPZ list' : ''"
      flat
    >
      <v-card-title class="d-flex align-center justify-space-between pe-2">
        <div v-if="!$vuetify.display.mobile">SPZ list</div>

        <v-text-field
          v-model="search"
          label="Search"
          density="compact"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          hide-details
          single-line
          min-width="45%"
          max-width="45"
        ></v-text-field>

        <div>
          <v-btn
            color="primary"
            dark
            @click="dialog = true"
          >
            Add SPZ
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :sort-by="sortBy"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:item.type="{ item }">
          <v-chip :color="item.type === 'allowed' ? 'green' : 'red'" variant="outlined">{{ item.type }}</v-chip>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.created_at="{ item }">
          {{ formattedDate(item) }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.inspections="{ item }">
          <v-btn icon variant="text"  @click="openInspectionsWithSearchedPlate(item)">
            <v-icon>mdi-car-search</v-icon>

            <v-tooltip
              activator="parent"
              location="bottom"
            >Show plate inspections</v-tooltip>
          </v-btn>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.action="{ item }">
          <v-btn v-if="item.type === 'allowed'" class="mr-2" icon variant="text"  @click="disablePlate(item)">
            <v-icon >mdi-car-off</v-icon>

            <v-tooltip
              activator="parent"
              location="bottom"
            >Ban plate</v-tooltip>
          </v-btn>

          <v-btn v-else class="mr-2" icon variant="text"  @click="allowPlate(item)">
            <v-icon >mdi-car</v-icon>

            <v-tooltip
              activator="parent"
              location="bottom"
            >Allow plate</v-tooltip>
          </v-btn>

          <v-btn icon variant="text"  @click="deletePlate(item)">
            <v-icon>mdi-trash-can-outline</v-icon>

            <v-tooltip
              activator="parent"
              location="bottom"
            >Delete plate</v-tooltip>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    

    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card class="pt-5">
        <v-card-title class="text-center">
          <span class="text-h5">Add SPZ</span>
        </v-card-title>

        <v-card-text>
          <v-text-field v-model="spz" variant="outlined" label="SPZ"></v-text-field>
        </v-card-text>

        <v-card-actions class="px-5">
          <v-btn
            color="red-darken-1"
            variant="text"
            @click="closeDialog"
          >
            Cancel
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="addPlate"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style></style>

