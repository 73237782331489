import { createApp } from 'vue'
import router from './router'
import App from './App.vue'

// Vuetify
import vuetify from '@/plugins/vuetify'

createApp(App)
.use(vuetify)
.use(router)
.mount('#app')
