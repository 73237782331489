<script setup>
import { onMounted, ref } from 'vue';
import { internalApiRequest } from '@/services/apiService.js';
import { useRoute } from 'vue-router'

const route = useRoute()

const items = ref([])
const sortBy = ref([{ key: 'update_date', order: 'desc' }])
const headers = ref([
  {
    align: 'center',
    key: 'plate',
    title: 'Plate'
  },
  {
    align: 'center',
    key: 'result',
    title: 'Result'
  },
  {
    align: 'center',
    key: 'inspection_date',
    title: 'Inspection date'
  },
  {
    align: 'center',
    key: 'update_date',
    title: 'Update date'
  },
  {
    align: 'center',
    key: 'image',
    title: 'Image'
  },
  {
    align: 'end',
    key: 'action',
    title: '',
    sortable: false
  },
])
const spz = ref('')
const search = ref('')
const dialog = ref(false)
const addImgDialog = ref(false)
const fileInput = ref('')
const inspectionId = ref('')
const showImageDialog = ref(false)
const inspectionImg = ref('')

function fetchInspections() {
  internalApiRequest({
    method: 'GET',
    url: 'api/v1/inspections',
  }).then((resp) => {
    items.value = resp
  }).catch((err) => {
    console.log(err)
  })
} 

function addInspections() {
  internalApiRequest({
    method: 'POST',
    url: 'api/v1/inspections',
    data: {
      plate: spz.value
    }
  }).then(() => {
    closeDialog();
    fetchInspections();
  }).catch(() => {
    closeDialog();
    fetchInspections();
  })
}

function closeDialog() {
  inspectionId.value = ''
  inspectionImg.value = ''
  fileInput.value = ''
  spz.value = ''
  dialog.value = false
  showImageDialog.value = false
  addImgDialog.value = false
}

function openAddImageDialog(item) {
  inspectionId.value = item.id
  addImgDialog.value = true
}

function addImageToInspection() {
  const formData = new FormData();

  formData.append('file', fileInput.value)

  internalApiRequest({
    method: 'PUT',
    url: `api/v1/inspections/${inspectionId.value}`,
    data: formData
  }).then(() => {
    closeDialog();
    fetchInspections();
  }).catch(() => {
    closeDialog();
    fetchInspections();
  })
}

function showImage(item) {
  inspectionImg.value = item.image
  showImageDialog.value = true;
}


function formattedDate(item) {
  const options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  
  return new Date(item).toLocaleDateString('cs-CZ', options);
}

onMounted(() => {
  fetchInspections()

  if(route?.query?.plate) {
    search.value = route?.query?.plate
  }
});
</script>

<template>
  <v-container fluid>
    <v-card
      :title="$vuetify.display.mobile ? 'Inspection list' : ''"
      flat
    >
      <v-card-title class="d-flex align-center justify-space-between pe-2">
        <div v-if="!$vuetify.display.mobile">Inspection list</div>

        <v-text-field
          v-model="search"
          label="Search"
          density="compact"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          hide-details
          single-line
          min-width="45%"
          max-width="45"
        ></v-text-field>

        <div>
          <v-btn
            color="primary"
            dark
            @click="dialog = true"
          >
            Add Inspection
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :sort-by="sortBy"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:item.result="{ item }">
          <v-chip :color="item.result === 'pass' ? 'green' : 'red'" variant="outlined">{{ item.result }}</v-chip>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.inspection_date="{ item }">
          {{ formattedDate(item.inspection_date) }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.update_date="{ item }">
          {{ formattedDate(item.update_date) }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.image="{ item }">
          <v-btn v-if="item.image" icon variant="text"  @click="showImage(item)">
            <v-icon>mdi-image</v-icon>

            <v-tooltip
              activator="parent"
              location="bottom"
            >Show plate image</v-tooltip>
          </v-btn>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.action="{ item }">
          <v-btn icon variant="text"  @click="openAddImageDialog(item)">
            <v-icon>mdi-file-image-plus</v-icon>

            <v-tooltip
              activator="parent"
              location="bottom"
            >Add plate image</v-tooltip>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card class="pt-5">
        <v-card-title class="text-center">
          <span class="text-h5">Add Inspection</span>
        </v-card-title>

        <v-card-text>
          <v-text-field v-model="spz" variant="outlined" label="SPZ"></v-text-field>
        </v-card-text>

        <v-card-actions class="px-5">
          <v-btn
            color="red-darken-1"
            variant="text"
            @click="closeDialog"
          >
            Cancel
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="addInspections"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addImgDialog"
      max-width="500px"
    >
      <v-card class="pt-5">
        <v-card-title class="text-center">
          <span class="text-h5">Image</span>
        </v-card-title>

        <v-card-text>
          <v-file-input v-model="fileInput" label="File input" variant="outlined"></v-file-input>
        </v-card-text>

        <v-card-actions class="px-5">
          <v-btn
            color="red-darken-1"
            variant="text"
            @click="closeDialog"
          >
            Cancel
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="addImageToInspection"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    

    <v-dialog
      v-model="showImageDialog"
      max-width="500px"
    >
      <v-card class="pt-5">
        <v-card-title class="text-center">
          <span class="text-h5">Uploaded image</span>
        </v-card-title>

        <v-card-text>
          <v-img max-width="500px" :src="inspectionImg.file_url"></v-img>
        </v-card-text>

        <v-card-actions class="px-5">
          <v-btn
            color="red-darken-1"
            variant="text"
            @click="closeDialog"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style></style>

