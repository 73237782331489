<script setup>
import { useRouter } from 'vue-router'

const router = useRouter()

function goTo(name) {
  router.push({
    name: name,
  })
}
</script>

<template>
  <v-app class="ma-0 pa-0">
    <v-toolbar class="bg-black" :class="!$vuetify.display.mobile ? 'px-4' : ''">
      <v-toolbar-title class="text-left ml-5 cursor-pointer" @click="goTo('Home')">SPZ sken</v-toolbar-title>

      <v-btn class="mr-5" variant="outlined" @click="goTo('SPZ')">SPZ</v-btn>

      <v-btn variant="outlined" @click="goTo('Inspections')">Inspections</v-btn>
    </v-toolbar>

    <v-main>
      <router-view />
    </v-main>
    
    <v-footer class="ma-0 pa-0" app>
      <div class="px-10 py-2 bg-black text-right w-100">
        {{ new Date().getFullYear() }} — <strong>Trosky</strong>
      </div>
    </v-footer>
  </v-app>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>