import { createWebHistory, createRouter } from 'vue-router'

import HomeView from '@/view/HomeView.vue'
import SPZView from '@/view/SPZView.vue'
import InspectionView from '@/view/InspectionView.vue'


const routes = [
  { path: '/', name: 'Home', component: HomeView },
  { path: '/spz', name: 'SPZ', component: SPZView },
  { path: '/inspections', name: 'Inspections', component: InspectionView },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router