import axios from 'axios';

axios.defaults.baseURL = 'https://spolicajt.hudebniciulicnici.eu/';

export async function internalApiRequest(requestConfig) {
  return axios
    .request({
      ...requestConfig,
      headers: {
        ...requestConfig.headers,
      },
    })
    .then((resp) => resp.data);
}